import Image from "next/image";
import Link from "next/link";

const controlTypes = {
  disease: "Hastalık Mücadelesi",
  pest: "Zararlı Mücadelesi",
  weed: "Yabancı Ot Mücadelesi",
};

const ControlMiniListing = ({ slug, title, type, category, image }) => {
  return (
    <Link
      href={"/zirai-mucadele/" + slug}
      locale="tr"
      className="flex gap-4 px-3 py-2 transition-colors duration-100 ease-out border border-gray-200 rounded-md cursor-pointer sm:gap-6 hover:border-primaryLight"
    >
      <div className="relative w-16 h-16 overflow-hidden rounded-md min-w-[4rem]">
        <Image
          layout="fill"
          objectPosition="center"
          objectFit="cover"
          alt={title}
          src={`${process.env.NEXT_PUBLIC_IMAGE_URL}${image}?key=product-listing-mini`}
        />
      </div>
      <div className="truncate">
        <div className="text-sm text-neutral-600">{controlTypes[type]}</div>
        <div className="font-semibold ">{title}</div>
        <div className="text-sm text-neutral-400">{category}</div>
      </div>
    </Link>
  );
};

export default ControlMiniListing;
